<template>
  <v-form class="expande-horizontal wrap pa-3 centraliza" ref="form">
    <v-flex xs12 md10 class="py-6">
      <div class="expande-horizontal centraliza column wrap">
        <v-img
          width="120"
          src="img/logo.png"
          alt=""
          class="expande-horizontal wrap"
        />
        <span class="pt-3 fonte font-weight-bold" :style="`color: ${$theme.primary};`">
          Acesse para gerenciar seu blog
        </span>
      </div>
    </v-flex>

    <div class="expande-horizontal centraliza wrap">
      <v-flex xs12 md3>
        <div class="expande-horizontal centraliza wrap">
          <v-flex class="ma-1 mb-3" xs12>
            <span :style="`color: ${$theme.primary};`" class="fonte fonteMini font-weight-bold"> E-mail</span>
            <v-text-field
              placeholder="Ex: email@gmail.com"
              :color="$theme.primary"
              solo
              flat
              ref="mailField"
              background-color="#f2f2f2"
              dense
              rounded
              hide-details
              :rules="[v => !!v || 'Preencha este campo']"
              v-model="payload.username"
              autocomplete="new-login"
              >
            </v-text-field>
          </v-flex>
          
          <v-flex class="ma-1 mt-0" xs12>
            <span :style="`color: ${$theme.primary};`" class="fonte fonteMini font-weight-bold"> Senha</span>
            <v-text-field
              dense
              rounded
              placeholder="Digite sua senha"
              :color="$theme.primary"
              solo
              flat
              background-color="#f2f2f2"
              @click:append="typePass = !typePass"
              :append-icon="typePass ? 'mdi-eye' : 'mdi-eye-off'"
              @keyup.enter="validaCampo"
              :type="typePass ? 'text' : 'password'"
              :rules="[v => !!v || 'Preencha este campo']"
              v-model="payload.senha"
              autocomplete="new-senha"
            >
            </v-text-field>
          </v-flex>
      
          <v-flex class="ma-1 mb-6" xs12 md6>
            <div class="expande-horizontal centraliza">
              <v-btn
                block
                class="px-12 elevation-0 fonte text-capitalize font-weight-bold"
                dark
                rounded
                :color="$theme.secondary"
                @click="validaCampo"
                >Let go
                <v-icon>mdi-rocket</v-icon>
      
                </v-btn
              >
            </div>
          </v-flex>
      
          <v-flex class="ma-1 mt-3 mb-6" xs12 md6>
            <div class="expande-horizontal">
              <v-spacer></v-spacer>
              <v-btn
                small
                :color="$theme.primary"
                @click="setModalForgotPassword(true)"
                text
                class="fonte text-capitalize"
              >
                <span>
                  Esqueci minha senha
                </span>
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>

    </div>

    <!-- <v-flex class="ma-1 mt-6" xs12 md6>
      <div class="expande-horizontal centraliza">
        <span class="fonte">Ainda não tem uma conta?</span>
        <span
          @click="$router.push('/comprar-plano/essencial')"
          class="fonte ml-1"
          :style="`color: ${$theme.secondary}; cursor: pointer;`"
          >Assine agora</span
        >
      </div>
    </v-flex> -->
    <ForgotPass />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ForgotPass from "./ForgotPassword.vue";
export default {
  data() {
    return {
      payload: {
        username: "",
        senha: ""
      },
      typePass: false
    };
  },
  components: {
    ForgotPass
  },
  methods: {
    ...mapActions(["createSession", "setModalForgotPassword"]),
    validaCampo() {
      if (this.$refs.form.validate()) {
        this.createSession(this.payload);
      }
    }
  },
  created() {
    setTimeout(() => {
      this.$refs.mailField.focus();
    }, 300);
  }
};
</script>
